<template>
  <div>
    <PageHeader
      fileName="header-17"
      class="img-top-center"
      withTablet
      withMobile
    >
      <h3>{{ $lang("title") }}</h3>
    </PageHeader>

    <section class="bg-dark pt-7 pb-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 d-flex flex-column">
            <h2 class="mb-4" v-html="$lang('header.title')" />
          </div>
          <div class="col-lg-6 offset-lg-1">
            <h6 class="fw-400 lh-3 mb-4 mt-3">
              {{ $lang("header.paragraph1") }}
            </h6>
            <p>
              {{ $lang("header.paragraph2") }}
            </p>
            <p>
              {{ $lang("header.paragraph3") }}
            </p>
          </div>
        </div>

        <div class="row mt-7 pb-6 border-secondary border-bottom">
          <div class="col-lg-4">
            <div
              class="d-flex flex-column justify-content-between gap-5 h-100 pb-5 pb-lg-0"
            >
              <h4>{{ $lang("subtitle") }}</h4>
              <div>
                <p class="mb-0 h6">
                  {{ $lang("contact_form.accredited", true) }}<br />
                  {{ $lang("contact_form.accredited2", true) }}
                </p>
                <div class="row">
                  <div class="col-5 pr-0">
                    <img
                      src="/imgs/nl_college_white.png"
                      alt="NL College"
                      class="img-fluid"
                    />
                  </div>
                  <div class="col-3">
                    <a
                      href="https://acreditacion.cervantes.es/"
                      target="_blank"
                    >
                      <img
                        src="/imgs/cervantes_white.png"
                        alt="Cervantes Institute"
                        class="img-fluid"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 offset-lg-2">
            <div class="card card-secondary p-md-5 p-3 mb-4 mb-lg-0">
              <div class="card-body">
                <ContactForm theme="dark" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import ContactForm from "@/components/ContactForm.vue";

export default {
  components: {
    PageHeader,
    ContactForm,
  },
  data: () => ({}),
};
</script>

<style></style>
